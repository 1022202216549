import React, { useState, useEffect } from "react";
import { getStore } from "store/storeUtils";
import { useNavigate } from "react-router-dom";
import Modal from "atoms/Modal";
import { LOGIN_MODALS, OTP_TYPES } from "global/constants";
import { Text, Button, VStack, Divider, Stack } from "native-base";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { apiLogin } from "api/apiCall";
import { showCustomToast, showErrorToast } from "utils/utilities";
import useCustomToast from "utils/hooks";

const LoginModal = () => {
  const { t } = useTranslation("common");
  const toast = useCustomToast();
  const navigate = useNavigate();
  const { modal, resetModal, loginData, setLoginData, resetLoginData } = getStore();
  const [typesOtp, setTypesOtp] = useState(loginData?.otpType);
  const direction = ["column", "column", "column", "row", "row"];

  const {
    mutate: performLogin,
    isSuccess,
    data,
    isError,
    error,
  } = useMutation({
    mutationFn: (otpType) => apiLogin({ otpType, mobileNumber: loginData.phoneNumber }),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast({ error, toast, navigate, resetLoginData });
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      const newLoginData = {
        ...loginData,
        otpType: typesOtp,
        token: data?.token,
      };
      setLoginData(newLoginData);
      showCustomToast({
        title: "login.otpResent",
        status: "success",
        variant: "solid",
        toast,
      });
      resetModal();
    }
  }, [isSuccess, data]);

  const callLoginApiWithOtpType = (otpType = "") => {
    performLogin(otpType);
    setTypesOtp(otpType);
  };

  return (
    <>
      {modal.type === LOGIN_MODALS.reset ? (
        <Modal height="200px" width="350px" title={t("modal.login.title")} reset={resetModal} closeable>
          <VStack space="3">
            <Divider />
            <VStack p="10px" space="3">
              <Text variant="xs">{t("modal.login.description")}</Text>
            </VStack>
            <Divider />
            <Stack
              direction={direction}
              alignItems="center"
              justifyContent="center"
              w="100%"
              space={3}
              flex={1}
              px="15px"
            >
              <Button variant="whatsapp" onPress={() => callLoginApiWithOtpType(OTP_TYPES.WHATSAPP)} flex={0.5}>
                <Text variant="xs" color={colors.neutral.cotton} textAlign="center" testID="txtWhatsapp">
                  Whatsapp
                </Text>
              </Button>
              <Button variant="solid" onPress={() => callLoginApiWithOtpType(OTP_TYPES.SMS)} flex={0.5}>
                <Text variant="xs" color={colors.neutral.cotton} textAlign="center" testID="txtSms">
                  SMS
                </Text>
              </Button>
            </Stack>
          </VStack>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default LoginModal;
