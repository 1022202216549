import { Alert, VStack, HStack, Text, IconButton, CloseIcon } from "native-base";
import { useTranslation } from "react-i18next";

export function shouldRenderIconButton(isClosable, variant, toast, id) {
  return isClosable ? (
    <IconButton
      variant="unstyled"
      icon={<CloseIcon size="3" />}
      _icon={{
        color: variant === "solid" ? "lightText" : "darkText",
      }}
      onPress={() => toast.close(id)}
    />
  ) : null;
}

const ToastAlert = ({ toast, id, status, variant, title, description, isClosable, ...rest }) => {
  const { t } = useTranslation("common");
  return (
    <Alert
      maxWidth="100%"
      alignSelf="center"
      flexDirection="row"
      status={status || "info"}
      variant={variant}
      mx="15px"
      {...rest}
    >
      <VStack space={1} flexShrink={1} w="100%">
        <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
          <HStack space={2} flexShrink={1} alignItems="center">
            {/* <Alert.Icon /> */}
            <Text
              fontSize="md"
              fontWeight="medium"
              flexShrink={1}
              color={variant === "solid" ? "lightText" : variant !== "outline" ? "darkText" : null}
            >
              {t(title)}
            </Text>
          </HStack>
          {shouldRenderIconButton(isClosable, variant, toast, id)}
        </HStack>
        <Text px="6" color={variant === "solid" ? "lightText" : variant !== "outline" ? "darkText" : null}>
          {description}
        </Text>
      </VStack>
    </Alert>
  );
};

export default ToastAlert;
